<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='2001'>
		  <nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>

		<div class='paysuccess-wrap container'>
			<el-card class='box-card'>
				<el-result
					icon="success" 
					title="支付成功" 
					subTitle="请在个人中心查看课程">
				  <template #extra>
					<el-button 
						type="primary" 
						 @click='toCourseList'
						size="medium">课程列表</el-button>
					<el-button 
						type="primary" 
						@click='toStudyCenter'
						size="medium">个人中心</el-button>
				  </template>
				</el-result>
			</el-card>
		</div>

		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script>
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	export default{
		name:'paySuccess',
		data(){
			return{
				
			}
		},
		components:{
			NavBar,
			ToolBox,
			WebFooter
		},
		methods:{
			toStudyCenter(){//我的课程
				this.$router.push({
					path:'/user'
				});
			},
			toCourseList(){//课程列表
				this.$router.push({
			    path:'/course'
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.paysuccess-wrap{
		margin:20px auto;
	}
</style>